<template>
	<v-list-item class="add-on-tile">
		<div class="add-on-content">
			<v-list-item-subtitle class="add-on-description">
				{{ addOn.description }}
			</v-list-item-subtitle>
			<v-list-item-subtitle class="add-on-price" v-if="addOn.price">
				{{ money(addOn.price) }}
			</v-list-item-subtitle>
		</div>

		<v-text-field
			v-if="addOn.requireNotes"
			v-model="addOn.customerNotes"
			:label="personalizationNotesLabel"
			required
			outlined
			:rules="customerNotesRules"
			ref="customerNotes"
			variant="outlined"
			class="add-on-customer-notes-field"
		/>
	</v-list-item>
</template>

<script>
import { money } from '@/components/utils/format-money';
import { nextTick } from 'vue';

export default {
	name: 'ProductAddOnSummary',
	props: ['addOn'],
	computed: {
		customerNotesRules: function() {
			return [
				(value) => {
					return !!value || 'Please enter a short note';
				},
				(value) => {
					let maxLength = this.$store.getters.maxPersonalizationNotesLength;
					return !value || value.length <= maxLength || `Maximum notes length is ${maxLength} characters`;
				}
			];
		},
		personalizationNotesLabel() {
			if(this.addOn.notesLabel) {
				return this.addOn.notesLabel;
			} else if((this.addOn.type || '').indexOf('Name on cover') !== -1) {
				return 'Type name exactly how you want it shown on the cover';
			} else {
				return 'Type your personalization notes here';
			}
		}
	},
	methods: {
		money
	},
	mounted: function() {
		if(this.$refs.customerNotes) {
			nextTick(() => {
				this.$refs.customerNotes.validate();
			});
		}
	}
};
</script>

<style scoped>
	.add-on-content {
		overflow: visible;
	}

	.add-on-customer-notes-field {
		width: 100%;
	}

	.add-on-tile:deep(.v-list-item__content) {
		overflow: visible;
	}
</style>