<template>
	<v-container>
		<v-card class="cart-summary elevation-10">
			<v-list>
				<h2
					v-if="$store.state.cart.items.length"
					style="padding-bottom: 0.5em"
				>
					<v-icon>shopping_cart</v-icon> Summary
				</h2>

				<div v-for="(item, index) in $store.state.cart.items" :key="index" class="product-item">
					<v-list-item class="product-tile">
						<div class="item-content">
							<v-list-item-title class="product-description">
								<span>{{ item.product.name }}</span>
								<span v-if="item.product.type">&nbsp;- {{ item.product.type }}</span>
								<v-list-item-subtitle class="item-price">
									{{ money(item.product.price) }}
								</v-list-item-subtitle>
							</v-list-item-title>
						</div>

						<v-list-item-action>
							<v-btn icon="close" variant="text" color="red lighten-2" ripple @click="removeItem(item)" />
						</v-list-item-action>
					</v-list-item>

					<product-add-on-summary v-for="(addOn, addOnIndex) in item.addOns" :key="addOnIndex" :add-on="addOn" />
				</div>

				<v-divider class="cart-summary-divider" v-if="$store.state.cart.items.length" />

				<v-list-subheader>Order Summary</v-list-subheader>

				<v-list-item v-if="$store.getters.shippingFee && $store.state.project.shippingRequired">
					<div>
						Sub Total: {{ moneyCurrency($store.getters.subTotal) }}
						<v-list-item-subtitle>Shipping: {{ moneyCurrency($store.getters.shippingFee) }}</v-list-item-subtitle>
					</div>
				</v-list-item>

				<v-text-field
					v-model="currentCoupon"
					label="Enter coupon code"
					outlined
					class="coupon-field"
					v-if="$store.state.project.hasCoupons"
					:append-icon="currentCoupon.length > 1 ? 'done' : ''"
					@click:append="applyCouponCode"
					@keyup.enter="applyCouponCode"
					@blur="applyCouponCode"
					@keyup="clearCouponError"
					:loading="couponLoading"
					hide-details="auto"
					:error-messages="couponError"
					variant="outlined"
				/>

				<v-list-item>
					<div>
						<v-list-item-subtitle v-if="$store.getters.tieredDiscount">
							Bulk discount: -{{ moneyCurrency($store.getters.tieredDiscount) }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="$store.state.cart.coupons.length">
							Coupon: -{{ moneyCurrency($store.state.cart.coupons[0].price) }} ({{ $store.state.cart.coupons[0].code }})
						</v-list-item-subtitle>
						<b>Total: {{ moneyCurrency($store.getters.preTaxTotal) }}</b>
					</div>
				</v-list-item>
			</v-list>

			<v-card-actions class="summary-card-actions">
				<v-btn class="cart-checkout-button" :disabled="canCheckout" @click="checkout" variant="elevated" color="primary">
					Checkout
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import ProductAddOnSummary from './ProductAddOnSummary.vue';
import { money, moneyCurrency } from '@/components/utils/format-money';

export default {
	name: 'CartSummary',
	data: function() {
		return {
			currentCoupon: '',
			couponLoading: false,
			couponError: ''
		};
	},
	computed: {
		canCheckout: function() {
			if(this.$store.state.cart.items.length === 0 || this.couponLoading) {
				return true;
			}

			for(var i = 0; i < this.$store.state.cart.items.length; i++) {
				let item = this.$store.state.cart.items[i];
				if(!item.addOns) {
					continue;
				}

				for(var j = 0; j < item.addOns.length; j++) {
					let addOn = item.addOns[j];

					if(addOn.requireNotes && (!addOn.customerNotes || addOn.customerNotes.length > this.$store.getters.maxPersonalizationNotesLength)) {
						return true;
					}
				}
			}

			return false;
		}
	},
	methods: {
		applyCouponCode() {
			if(this.currentCoupon.length <= 1 || this.couponLoading) {
				return false;
			}

			this.couponLoading = true;
			this.$store.dispatch({
				type: 'applyCoupon',
				projectId: this.$route.params.id,
				couponCode: this.currentCoupon
			}).then((coupon) => {
				this.couponLoading = false;
				if(coupon) {
					this.currentCoupon = '';
				} else {
					this.couponError = 'Invalid coupon code';
				}
			}).catch((error) => {
				this.couponLoading = false;
				this.couponError = error.message;
			});
		},
		clearCouponError() {
			this.couponError = '';
		},
		removeItem: function(item) {
			this.$store.commit({
				type: 'removeLineItemFromCart',
				item: item
			});
		},
		checkout: function() {
			// Crappy hack to force the state to actually update with the add-on descriptions!
			this.$store.commit('setCartItems', this.$store.state.cart.items);

			this.$router.push('/checkout');
			document.documentElement.scrollTop = 0;
		},

		money,
		moneyCurrency
	},
	components: {
		ProductAddOnSummary
	}
};
</script>

<style scoped>
	.cart-summary-divider {
		margin: 0.4em 0;
	}

	.item-content {
		flex-grow: 1;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.item-quantity {
		width: auto;
		width: initial;
		flex: 1 1 auto;
	}
	.item-price {
		width: auto;
		width: initial;
		flex: 0 1 auto;
		margin-left: auto;
	}

	.add-on-tile {
		margin-left: 3em;
	}

	.product-item {
		margin: 0.5em 0;
	}

	@media (max-width: 600px) {
		.add-on-tile {
			margin-left: 1.5em;
		}
	}

	.summary-card-actions {
		justify-content: center;
	}

	.coupon-field {
		width: 95%;
		margin: 0 auto !important;
	}

	.product-tile:deep(.v-list-item__content) {
		display: flex;
		overflow: visible;
	}
</style>