<template>
	<div>
		<v-container v-for="product in $store.state.project.products" :key="product.id">
			<product-card :product="product" />
		</v-container>
	</div>
</template>

<script>
import ProductCard from '@/components/store/ProductCard.vue';

export default {
	name: 'ProductListing',
	components: {
		ProductCard
	}
};
</script>

<style scoped>

</style>