import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, md } from 'vuetify/iconsets/md';

export default createVuetify({
	theme: {
		themes: {
			light: {
				dark: false,
				colors: {
					primary: '#0091D2',
					secondary: '#00BCD4',
					error: '#ff5252'
				}
			}
		}
	},
	icons: {
		defaultSet: 'md',
		aliases,
		sets: {
			md
		}
	}
});