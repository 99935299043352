<template>
	<v-snackbar v-model="snackbar" :timeout="timeout">
		New version available!

		<template #actions="attrs">
			<v-btn color="primary" variant="outlined" v-bind="attrs" @click="refreshApp">
				Refresh
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {
			snackbar: false,
			timeout: 10000
		};
	},
	methods: {
		refreshApp() {
			// Prevent double clicking
			if(!this.snackbar) {
				return;
			}

			this.snackbar = false;
			window.location.reload();
		}
	},
	created() {
		if(!navigator.serviceWorker) {
			return;
		}

		// Refresh all open app tabs when a new service worker is installed.
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			this.snackbar = true;
		});
	}
};
</script>