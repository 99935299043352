import axios from 'axios';
import clone from 'clone';

export default {
	getOrder({ orderId, accessCode }) {
		return new Promise((resolve, reject) => {
			axios.get(import.meta.env.VITE_PLIC_BOOKS_HOST + '/api/v1/subject-orders', {
				params: {
					orderId,
					accessCode
				}
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	postOrder(data) {
		data.orderTotal = Math.round(data.orderTotal * 100);
		data.cart = data.cart.map((item) => {
			item = clone(item);

			item.product.price = Math.round(item.product.price * 100);
			item.addOns.forEach((addOn) => {
				addOn.price = Math.round(addOn.price * 100);
			});

			return item;
		});
		if(data.shippingFee) {
			data.shippingFee = Math.round(data.shippingFee * 100);
		}

		return new Promise((resolve, reject) => {
			axios.post(import.meta.env.VITE_PLIC_BOOKS_HOST + '/api/v1/subject-orders', data).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	chargeOrder: function(data) {
		return new Promise((resolve, reject) => {
			axios.post(import.meta.env.VITE_PLIC_BOOKS_HOST + '/api/v1/subject-orders', data).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				if(error.code === 'ECONNABORTED') {
					this.getOrder({
						accessCode: data.accessCode
					}).then(({order}) => {
						if(order.state === 'paid') {
							resolve({order});
						} else {
							reject(new Error('Failed to charge order: timeout'));
						}
					}).catch((error) => {
						reject(error);
					});
				} else {
					reject(error);
				}
			});
		});
	},
	checkCoupon({ projectId, couponCode }) {
		return new Promise((resolve, reject) => {
			axios.get(import.meta.env.VITE_PLIC_BOOKS_HOST + `/api/v1/yearbook-store-coupon?projectId=${projectId}&couponCode=${couponCode}`).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};