import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import createStore from './store';
import './styles';
import LogRocket from 'logrocket';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { registerSW } from 'virtual:pwa-register';

const store = createStore();

let bugsnagClient;
let bugsnagVue;
if(import.meta.env.VITE_BUGSNAG_API_KEY) {
	let bugsnagEnabled = true;
	Bugsnag.start({
		apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
		appVersion: import.meta.env.VITE_APP_VERSION,
		enabledReleaseStages: ['production', 'staging'/*, 'development'*/],
		releaseStage: import.meta.env.VITE_RELEASE_STAGE,
		plugins: [new BugsnagPluginVue()],
		onError: function(event) {
			if(!bugsnagEnabled
				|| event.errors[0]?.errorMessage?.includes('readonly property') || event.errors[0]?.errorMessage?.includes('has only a getter')
				// Service worker fails mostly due to client network issues
				|| event.errors[0]?.errorMessage?.includes('Failed to update a ServiceWorker') || event.errors[0]?.errorMessage?.includes('service-worker.js load failed') || event.errors[0]?.errorMessage?.includes('newestWorker is null') || event.errors[0]?.errorMessage?.includes('ServiceWorker script at ') || event.errors[0]?.errorMessage?.includes('ServiceWorker for scope ') || event.errors[0]?.errorMessage?.includes('service worker registration') || event.errors[0]?.errorMessage?.includes('The Internet connection appears to be offline')
				// Can't control client network failing to load stripe API
				|| event.errors[0]?.errorMessage?.includes('fetching https://api.stripe.com')
			) {
				return false;
			}

			if(event.errors.length && ['TypeError', 'InvalidError'].includes(event.errors[0].errorClass) && event.errors[0].errorMessage && !event.groupingHash) {
				event.groupingHash = event.errors[0].errorMessage;
			}
		}
	});
	bugsnagVue = Bugsnag.getPlugin('vue');

	window.bugsnagClient = window.Bugsnag = Bugsnag;
	window.addEventListener('beforeunload', function() {
		bugsnagEnabled = false;
	});
} else if(import.meta.env.NODE_ENV === 'production') {
	console.warn('Bugsnag not initialized');
}

if(import.meta.env.VITE_LOG_ROCKET_INIT_KEY) {
	window.LogRocket = LogRocket;
	LogRocket.init(import.meta.env.VITE_LOG_ROCKET_INIT_KEY);

	let fullName = store.getters.fullName;
	if(fullName && store.state.cart.email) {
		LogRocket.identify(store.state.cart.email, {
			name: fullName,
			email: store.state.cart.email
		});
	}

	LogRocket.getSessionURL((sessionURL) => {
		if(bugsnagClient) {
			bugsnagClient.metaData = {
				LogRocketSessionUrl: sessionURL
			};
		}
	});
}

if(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID) {
	const script = document.createElement('script');
	script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
	script.async = true;
	document.head.appendChild(script);

	window.dataLayer = window.dataLayer || [];
	gtag('js', new Date());
	gtag('config', import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
}
function gtag(){window.dataLayer.push(arguments);}

// Hourly
const intervalMS = 60 * 60 * 1000;
registerSW({
	onRegistered(r) {
		r && setInterval(() => {
			r.update();
		}, intervalMS);
	}
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
if(bugsnagVue) {
	app.use(bugsnagVue);
}
app.mount('#app');