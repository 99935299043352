import converter from 'number-to-words';

window.storeCurrency = 'usd';

function money(value) {
	return '$' + parseFloat(value).toFixed(2);
}
function moneyCurrency(value) {
	let moneySymbol = '$';
	if(window.storeCurrency !== 'usd') {
		moneySymbol = window.storeCurrency.toUpperCase() + ' $';
	}

	return moneySymbol + parseFloat(value).toFixed(2);
}
function ordinal(value) {
	return converter.toWordsOrdinal(value);
}

export { money, moneyCurrency, ordinal };