<template>
	<v-row justify="center">
		<v-col sm="12" md="8" lg="4">
			<v-card class="help-link-card" v-if="hasAds">
				<v-card-text class="text-h4">
					For more information about designing your Ad visit <a href="https://iqplsupport.com/en/articles/5163454-plic-books-designing-your-ad" target="_blank">this link</a>.
					<span v-if="!!designDeadline">You must design and submit your Ad before {{ designDeadline }}</span>
				</v-card-text>
			</v-card>

			<v-card class="elevation-10" id="receipt-card">
				<v-list v-if="order" lines="two">
					<h2><v-icon>receipt</v-icon> Order Details</h2>

					<v-list-item><b>Order #:</b> &nbsp;{{ order.orderId }}</v-list-item>
					<template v-if="lineItemsHaveSubjectInfo">
						<template v-for="(lineItem, index) in order.lineItems">
							<v-list-item class="student-information-tile" :key="'s-' + index" v-if="lineItem.firstName">
								<v-list-item-title>
									<b>Student <template v-if="order.lineItems.length > 1">{{ index + 1 }}</template>:</b> {{ lineItem.firstName }} {{ lineItem.lastName }}
								</v-list-item-title>
								<v-list-item-subtitle v-for="field in displayFields(lineItem)" :key="field">
									{{ fieldLabel(field) }}: {{ lineItem[field] }}
								</v-list-item-subtitle>
							</v-list-item>
						</template>
					</template>
					<v-list-item class="student-information-tile" v-else-if="!!order.name.trim()">
						<v-list-item-title>
							<b>Student:</b> {{ order.name }}
						</v-list-item-title>
						<v-list-item-subtitle>
							Teacher: {{ order.teacher }}
						</v-list-item-subtitle>
						<v-list-item-subtitle>
							Grade: {{ order.grade }}
						</v-list-item-subtitle>
					</v-list-item>
					<v-list-item><b>Email:</b> &nbsp;{{ order.purchaseEmail }}</v-list-item>

					<v-divider class="order-summary-divider" />

					<h3>Products</h3>

					<div v-for="(item, index) in order.lineItems" :key="index">
						<v-list-item class="product-tile">
							<v-list-item-title class="product-description">
								<span>
									{{ item.product.name }}
								</span>
								<span v-if="item.product.type">
									&nbsp;- {{ item.product.type }}
								</span>
								<v-list-item-subtitle class="item-price">
									{{ money(item.product.price) }}
								</v-list-item-subtitle>
							</v-list-item-title>
						</v-list-item>

						<v-list-item v-for="(addOn, addOnIndex) in item.addOns" :key="addOnIndex" class="add-on-tile">
							<div class="add-on-content">
								<v-list-item-subtitle class="add-on-description">
									{{ addOn.description }}
								</v-list-item-subtitle>
								<v-list-item-subtitle class="add-on-price" v-if="addOn.price">
									{{ money(addOn.price) }}
								</v-list-item-subtitle>
							</div>

							<div class="add-on-customer-notes" v-if="addOn.customerNotes">
								<v-list-item-subtitle>
									Notes: {{ addOn.customerNotes }}
								</v-list-item-subtitle>
							</div>
						</v-list-item>
					</div>

					<v-divider class="order-summary-divider" />

					<v-list-item v-if="order.coupons || order.salesTax || order.shippingFee || order.tieredDiscount">
						Sub Total: {{ moneyCurrency(order.subTotal) }}
						<v-list-item-subtitle v-if="order.tieredDiscount">
							Bulk discount: -{{ moneyCurrency(order.tieredDiscount) }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="order.coupons && order.coupons.length">
							Coupon: -{{ moneyCurrency(order.coupons[0].price) }} ({{ order.coupons[0].code }})
						</v-list-item-subtitle>
					</v-list-item>

					<v-list-item class="shipping-information-tile" v-if="order.shippingInformation">
						<v-list-item-title>
							<b>Shipping:</b> <span v-if="order.shippingFee">
								{{ moneyCurrency(order.shippingFee) }}
							</span>
						</v-list-item-title>
						<v-list-item-subtitle>
							{{ order.shippingInformation.addressLine1 }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="order.shippingInformation.addressLine2">
							{{ order.shippingInformation.addressLine2 }}
						</v-list-item-subtitle>
						<v-list-item-subtitle>
							{{ order.shippingInformation.city }}, {{ order.shippingInformation.state }} {{ order.shippingInformation.zip }}
						</v-list-item-subtitle>
					</v-list-item>

					<v-list-item class="order-summary-total">
						<v-list-item-subtitle v-if="order.handlingFee">
							Handling Fee: {{ moneyCurrency(order.handlingFee) }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="order.salesTax">
							Tax: {{ moneyCurrency(order.salesTax) }}
						</v-list-item-subtitle>
						<b>Total: {{ moneyCurrency(order.orderTotal) }}</b>
					</v-list-item>
				</v-list>
			</v-card>

			<v-progress-circular indeterminate v-if="!order && !errorMessage" />
			<v-alert type="error" :model-value="errorMessage">
				{{ errorMessage }}
			</v-alert>

			<v-snackbar
				centered
				v-model="showSnackbar"
				color="success"
				:timeout="timeout"
				location="center"
			>
				{{ popupNotificationMessage }}

				<v-btn
					variant="outlined"
					@click="showSnackbar = false"
					style="margin-left: 0.5em"
				>
					Close
				</v-btn>
			</v-snackbar>
		</v-col>
	</v-row>
</template>

<script>
import moment from 'moment';

export default {
	name: 'OrderReceiptPage',
	data: function() {
		return {
			order: null,
			store: null,
			errorMessage: null,
			showSnackbar: false,
			timeout: 6000
		};
	},
	computed: {
		status: function() {
			var state = this.order?.state || 'open';
			return state.charAt(0).toUpperCase() + state.slice(1);
		},
		lineItemsHaveSubjectInfo: function() {
			return this.order?.lineItems.length && !!this.order?.lineItems[0].firstName;
		},
		hasAds() {
			return this.order && this.order.lineItems.length && this.order.lineItems.filter((lineItem) => {
				return lineItem.product.id === 'ads';
			}).length > 0;
		},
		hasPersonalizedPages() {
			return this.order && this.order.lineItems.length && this.order.lineItems.filter((lineItem) => {
				return (lineItem.addOns || []).filter((addOn) => {
					return addOn.type && addOn.type.indexOf('Personalized pages') !== -1;
				}).length > 0;
			}).length > 0;
		},
		popupNotificationMessage() {
			if(this.hasAds) {
				return 'Your order was successful!  You should be receiving an invitation to design your ad in PLIC Books soon!  Please double check your spam folder for an invite from PLIC Books.';
			} else if(this.hasPersonalizedPages) {
				return 'Your order was successful!  You should be receiving an invitation to design your personalized pages in PLIC Books soon!  Please double check your spam folder for an invite from PLIC Books.';
			} else {
				return 'Your order was successful!';
			}
		},
		designDeadline() {
			let deadline = this.store?.designSubmitDeadline;
			if(deadline) {
				return moment(deadline).format('MM/DD/Y');
			} else {
				return null;
			}
		}
	},
	methods: {
		displayFields(lineItem) {
			let keys = Object.keys(lineItem);
			keys = keys.filter(key => typeof lineItem[key] === 'string' && lineItem[key] && !['firstName', 'lastName'].includes(key));

			return keys;
		},
		fieldLabel(field) {
			return field.replace(/(^[a-z]+)|[0-9]+|[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|[0-9])/g, (match, first) => {
				if(first) {
					match = match[0].toUpperCase() + match.substr(1);
				}

				return match + ' ';
			});
		},

		money: function(value) {
			return '$' + (parseFloat(value) / 100).toFixed(2);
		},
		moneyCurrency: function(value) {
			let moneySymbol = '$';
			if(window.storeCurrency !== 'usd') {
				moneySymbol = window.storeCurrency.toUpperCase() + ' $';
			}

			return moneySymbol + (parseFloat(value) / 100).toFixed(2);
		}
	},
	mounted: function() {
		this.$store.dispatch({
			type: 'getOrder',
			accessCode: this.$route.params.id
		}).then(({order, store}) => {
			this.order = order;
			this.store = store;
			window.storeCurrency = this.order.currency;
			this.showSnackbar = true;
		}).catch((error) => {
			console.error(error);
			this.errorMessage = 'Failed to load receipt';
		});
	}
};
</script>

<style scoped>
	.order-summary-divider {
		margin: 0.4em 0;
	}

	.student-information-tile {
		margin: 0.4em 0;
	}

	.add-on-tile {
		margin-left: 3em;
		flex-direction: column;
	}

	.add-on-customer-notes {
		align-self: start;
		padding-top: 0;
		padding-bottom: 0;
	}

	.add-on-content {
		display: flex;
		width: 100%;
	}

	.help-link-card {
		margin-top: 0.5em;
		margin-bottom: 1em;
	}

	.text-h4 {
		line-height: 2.5rem;
	}
</style>