<template>
	<form>
		<v-radio-group v-model="isDirectShipping" v-if="!$store.state.project.shippingRequired">
			<v-radio label="No Shipping" :value="false" />
			<v-radio :label="directShippingLabel" :value="true" />
		</v-radio-group>

		<template v-if="isDirectShipping">
			<div class="text-h5">
				<v-icon>directions</v-icon> Where can we ship your Yearbook?
			</div>

			<v-text-field
				v-model="addressLine1"
				label="Address Line 1"
				:error-messages="addressLine1Errors"
				required
				@keyup.enter="nextStep"
				type="text"
				ref="mainAddress"
				variant="underlined"
			/>

			<v-text-field
				v-model="addressLine2"
				label="Address Line 2"
				@keyup.enter="nextStep"
				type="text"
				variant="underlined"
			/>

			<v-text-field
				v-model="city"
				label="City"
				:error-messages="cityErrors"
				required
				@keyup.enter="nextStep"
				type="text"
				variant="underlined"
			/>

			<v-text-field
				v-model="state"
				label="State/Province"
				:error-messages="stateErrors"
				required
				@keyup.enter="nextStep"
				type="text"
				variant="underlined"
			/>

			<v-text-field
				v-model="zip"
				label="ZIP/Postal Code"
				:error-messages="zipErrors"
				required
				@keyup.enter="nextStep"
				type="text"
				variant="underlined"
			/>
		</template>

		<v-btn
			text
			@click="prevStep"
		>
			Back
		</v-btn>
		<v-btn
			color="primary"
			@click="nextStep"
			style="margin-left: 1em"
		>
			Continue
		</v-btn>
	</form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import isPOBox from '../../utils/po-box-validator';
import { money } from '@/components/utils/format-money';

const noPOBox = (value, vm) => {
	if(vm.$store.state.project.allowPOBoxShipping) {
		return true;
	}

	return !isPOBox(value);
};

export default {
	name: 'ShippingInformation',
	emits: ['prev-step', 'next-step'],
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	data: function() {
		if(this.$store.state.cart.shippingInformation) {
			return {
				addressLine1: this.$store.state.cart.shippingInformation.addressLine1,
				addressLine2: this.$store.state.cart.shippingInformation.addressLine2,
				city: this.$store.state.cart.shippingInformation.city,
				state: this.$store.state.cart.shippingInformation.state,
				zip: this.$store.state.cart.shippingInformation.zip,
				isDirectShipping: this.$store.state.cart.isDirectShipping
			};
		} else {
			return {
				addressLine1: null,
				addressLine2: null,
				city: null,
				state: null,
				zip: null,
				isDirectShipping: this.$store.state.cart.isDirectShipping
			};
		}
	},
	watch: {
		isDirectShipping(value) {
			if(value) {
				this.$nextTick(() => {
					this.initAutoComplete();
				});
			}
		}
	},
	validations: {
		addressLine1: { required, noPOBox },
		city: { required },
		state: { required },
		zip: { required, minLength: minLength(5), maxLength: maxLength(7) }
	},
	computed: {
		addressLine1Errors() {
			const errors = [];
			if(this.v$.addressLine1.$dirty) {
				!this.v$.addressLine1.required.$invalid || errors.push('Address Line 1 is required');
				!this.v$.addressLine1.noPOBox.$invalid || errors.push('Shipping to PO Boxes is not supported');
			}

			return errors;
		},
		cityErrors() {
			const errors = [];
			if(this.v$.city.$dirty) {
				!this.v$.city.required.$invalid || errors.push('City is required');
			}

			return errors;
		},
		stateErrors() {
			const errors = [];
			if(this.v$.state.$dirty) {
				!this.v$.state.required.$invalid || errors.push('State is required');
			}

			return errors;
		},
		zipErrors() {
			const errors = [];
			if(this.v$.zip.$dirty) {
				!this.v$.zip.required.$invalid || errors.push('Zip code is required');
				!this.v$.zip.minLength.$invalid || errors.push('Zip code must be at least 5 numbers');
				!this.v$.zip.maxLength.$invalid || errors.push('Zip code must be less than 7 numbers');
			}

			return errors;
		},
		directShippingLabel() {
			return 'Direct ship (+' + money(this.$store.getters.rawShippingFee) + ')';
		}
	},
	methods: {
		nextStep: function() {
			if(!this.isDirectShipping) {
				this.$store.commit('setIsDirectShipping', this.isDirectShipping);
				this.$emit('next-step');
			} else {
				this.v$.$touch();
				let error = this.v$.$error;

				if(!error) {
					this.$store.commit('setShippingInformation', {
						addressLine1: this.addressLine1,
						addressLine2: this.addressLine2,
						city: this.city,
						state: this.state,
						zip: this.zip
					});
					this.$store.commit('setIsDirectShipping', this.isDirectShipping);

					this.$emit('next-step');
				}
			}
		},
		prevStep: function() {
			this.$emit('prev-step');
		},
		initAutoComplete() {
			if(!this.$refs.mainAddress) {
				return;
			}

			let inputWrapper = this.$refs.mainAddress.$el;
			let input = inputWrapper.querySelector('input');

			if(window.google && window.google.maps.places.Autocomplete) {
				this.autocomplete = new window.google.maps.places.Autocomplete(input, {
					types: ['geocode']
				});

				this.autocomplete.addListener('place_changed', () => {
					let place = this.autocomplete.getPlace();
					if(!place || !place.address_components) {
						return null;
					}

					let streetNumber;
					let streetNumberComponent = place.address_components.filter((component) => {
						return component.types[0] === 'street_number';
					})[0];
					if(streetNumberComponent) {
						streetNumber = streetNumberComponent.long_name;
					} else {
						streetNumber = '';
					}
					let streetName = place.address_components.filter((component) => {
						return component.types[0] === 'route';
					})[0].long_name;
					let street = streetNumber + ' ' + streetName;
					if(street) {
						this.addressLine1 = street;
					}

					let city = place.address_components.filter((component) => {
						return component.types[0] === 'locality';
					})[0].long_name;
					if(city) {
						this.city = city;
					}

					let state = place.address_components.filter((component) => {
						return component.types[0] === 'administrative_area_level_1';
					})[0].long_name;
					if(state) {
						this.state = state;
					}

					let zipComponent = place.address_components.filter((component) => {
						return component.types[0] === 'postal_code';
					})[0];
					if(zipComponent && zipComponent.long_name) {
						this.zip = zipComponent.long_name;
					}
				});
			}
		}
	},
	mounted: function() {
		this.initAutoComplete();
	}
};
</script>

<style>

</style>