export default function getFieldId(field) {
	if(field.field === 'Teacher/Home Room') {
		return 'teacher';
	}

	let parts = field.field.split(' ');
	parts[0] = parts[0].toLowerCase();
	for(let i = 1; i < parts.length; i++) {
		parts[i] = parts[i].slice(0, 1).toUpperCase() + parts[i].slice(1).toLowerCase();
	}

	return parts.join('');
}