<template>
	<v-row justify="center">
		<v-col sm="12" md="8">
			<checkout-stepper />
		</v-col>
	</v-row>
</template>

<script>
import CheckoutStepper from '@/components/checkout/CheckoutStepper.vue';

export default {
	name: 'CheckoutPage',
	components: {
		CheckoutStepper
	},
	mounted: function() {
		if(this.$store.state.cart.items.length === 0) {
			if(this.$store.state.project.id) {
				this.$router.push('/projects/' + this.$store.state.project.id);
			} else {
				this.$router.push('/');
			}
		}
	}
};
</script>

<style>

</style>