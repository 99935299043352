<template>
	<v-card class="elevation-10">
		<div class="product-card-header" :class="{ hasLogo: yearbookLogo }">
			<img class="product-card-logo" v-if="yearbookLogo" :src="yearbookLogo">

			<div class="product-card-title">
				<div class="text-h5 product-card-headline">
					{{ product.name }}
				</div>
				<div class="text-grey product-card-description" v-if="product.description">
					<template v-for="(line, index) in descriptionLines">
						{{ line }}
						<br v-if="index !== descriptionLines.length - 1">
					</template>
				</div>
			</div>
		</div>

		<v-list v-show="addOnsVisible" density="compact">
			<v-list-item class="add-on-tile has-checkbox" :ripple="false" @click.prevent="empty">
				<v-list-item-action>
					<v-checkbox :model-value="true" :disabled="Boolean(true)" color="primary" :hide-details="true" density="compact" class="add-on-checkbox" />
				</v-list-item-action>

				<div class="add-on-content">
					<v-list-item-title class="add-on-description">
						{{ yearbookCheckboxLabel }}
					</v-list-item-title>
					<v-list-item-subtitle class="add-on-price">
						+ {{ money(productPrice) }}
					</v-list-item-subtitle>
				</div>
			</v-list-item>

			<v-list-item v-for="addOn in product.addOns" :key="addOn.description" @click.prevent="toggleAddOn(addOn)" class="add-on-tile has-checkbox">
				<v-list-item-action>
					<v-checkbox
						v-model="addOn.enabled"
						color="primary"
						:hide-details="true"
						density="compact"
						class="add-on-checkbox"
						:disabled="addOn.required"
					/>
				</v-list-item-action>

				<div class="add-on-content">
					<v-list-item-title class="add-on-description">
						{{ addOn.description }}
					</v-list-item-title>
					<v-list-item-subtitle class="add-on-price" v-if="addOn.price">
						+ {{ money(addOn.price) }}
					</v-list-item-subtitle>
				</div>
			</v-list-item>
		</v-list>

		<v-radio-group v-model="product.type" class="product-type-group" style="margin-top: 0; width: 100%;" v-if="product.types" color="primary">
			<v-list>
				<v-list-item v-for="type in product.types" :key="type.id" @click.prevent="toggleType(product, type)" class="add-on-tile has-checkbox">
					<v-list-item-action>
						<v-radio :value="type.id" />
					</v-list-item-action>

					<div class="add-on-content">
						<v-list-item-title class="add-on-description">
							{{ type.description }}
						</v-list-item-title>
						<v-list-item-subtitle class="add-on-price">
							+ {{ money(type.price) }}
						</v-list-item-subtitle>
					</div>
				</v-list-item>
			</v-list>
		</v-radio-group>

		<v-alert type="success" v-if="hasTieredDiscount && isYearbook">
			Save {{ money($store.getters.nextTieredDiscount.discount) }} if you order another book!
		</v-alert>

		<v-card-actions class="product-card-actions">
			<v-btn @click="addToCart" class="add-to-cart-button" variant="elevated" color="primary">
				Add to Cart +{{ money(priceWithDiscount) }}
				<span v-if="hasTieredDiscount">(<span class="strikethrough">{{ money(price) }}</span>)</span>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { money } from '@/components/utils/format-money';

export default {
	name: 'ProductCard',
	props: {
		product: {
			default: function() {
				return {};
			}
		}
	},
	computed: {
		productPrice() {
			return this.product.price;
		},
		price: function() {
			var price = this.product.price;

			this.product.addOns.forEach((addOn) => {
				if(addOn.enabled) {
					price += addOn.price;
				}
			});

			return price;
		},
		priceWithDiscount() {
			let price = this.price;

			if(this.hasTieredDiscount) {
				price -= this.$store.getters.nextTieredDiscount.discount;
			}

			return price;
		},
		isYearbook() {
			return this.product.id === 'yearbook';
		},
		isAdoptABook() {
			return this.product.id === 'adopt-a-book';
		},
		yearbookLogo: function() {
			if(this.isYearbook && this.$store && this.$store.state.project.logo) {
				return this.$store.state.project.logo;
			} else {
				return null;
			}
		},
		addOnsVisible: function() {
			return !!this.product.addOns.length;
		},
		descriptionLines: function() {
			return this.product.description.split('\n');
		},
		hasTieredDiscount() {
			return (this.isYearbook || this.isAdoptABook) && this.$store.getters.nextTieredDiscount;
		},
		yearbookCheckboxLabel() {
			return this.$store?.state.project.yearbookCheckboxLabel ?? 'Yearbook';
		}
	},
	methods: {
		toggleAddOn(addOn) {
			if(addOn.required) {
				return;
			}

			addOn.enabled = !addOn.enabled;
		},
		toggleType(product, type) {
			product.type = type.id;
			product.price = type.price;
		},
		addToCart() {
			this.$store.commit({
				type: 'addProductToCart',
				product: this.product
			});

			this.product.addOns.forEach((addOn) => {
				addOn.enabled = addOn.required ?? false;
			});
		},
		empty() {},
		money
	},
	mounted() {
		(this.product.addOns ?? []).forEach(addOn => {
			if(addOn.required) {
				addOn.enabled = true;
			}
		});
	}
};
</script>

<style scoped>
	.product-card-header {
		display: flex;
		flex-direction: row;

		margin-top: 1em;
	}
	.product-card-logo {
		max-width: 16em;
		max-height: 16em;
		margin-right: 1em;

		padding: 0.25em;
		flex: 1 0 auto;
		align-self: center;
	}

	@media all and (orientation:portrait) {
		.product-card-header {
			flex-direction: column;
		}
	}
	@media all and (orientation:landscape) {
		.product-card-header.hasLogo .product-card-title {
			text-align: left;
		}

		.product-card-title {
			align-content: center;
		}
	}

	.product-card-title {
		display: flex;
		flex-direction: column;
		justify-content: center;

		flex: 1 1 auto;
		word-break: break-word;
	}
	.product-card-headline, .product-card-description {
		width: 100%;
		font-size: initial;
		line-height: 1.5rem;
	}
	.product-card-actions {
		justify-content: center;
	}

	.product-card-actions .expand-button {
		position: absolute;
		left: 50%;
	}

	.strikethrough {
		text-decoration: line-through;
	}

	.add-on-tile:deep(.v-list-item__content) {
		display: flex;
	}
	.add-on-content {
		display: flex;
		flex-grow: 1;
	}
	.add-on-checkbox {
		margin-right: 1.5em;
	}
</style>