<template>
	<div>
		<div v-if="!loading">
			<v-list>
				<h2><v-icon>view_headline</v-icon> Order Details</h2>

				<template v-for="(lineItem, index) in $store.state.cart.items">
					<v-list-item class="student-information-tile" :key="index + '-s'" v-if="lineItem.product.id !== 'adopt-a-book'">
						<div>
							<v-list-item-title>
								<b>Student <template v-if="$store.state.cart.items.length > 1">{{ index + 1 }}</template>:</b> {{ lineItem.firstName }} {{ lineItem.lastName }}
							</v-list-item-title>
							<v-list-item-subtitle v-for="field in displayFields(lineItem)" :key="field.field">
								{{ field.field }}: {{ lineItem[fieldId(field)] }}
							</v-list-item-subtitle>
						</div>
					</v-list-item>
				</template>
				<v-list-item><b>Email:</b> &nbsp;{{ $store.state.cart.email }}</v-list-item>

				<v-divider class="order-summary-divider" />

				<h3>Products</h3>

				<div v-for="(item, index) in $store.state.cart.items" :key="index + '-p'">
					<v-list-item class="product-tile">
						<div class="product-description">
							<v-list-item-title class="product-description">
								<span>{{ item.product.name }}</span>
								<span v-if="item.product.type">&nbsp;- {{ item.product.type }}</span>
								<v-list-item-subtitle class="item-price">
									{{ money(item.product.price) }}
								</v-list-item-subtitle>
							</v-list-item-title>
						</div>
					</v-list-item>

					<v-list-item v-for="(addOn, addOnIndex) in item.addOns" :key="addOnIndex" class="add-on-tile">
						<div class="add-on-content">
							<v-list-item-subtitle class="add-on-description">
								{{ addOn.description }}
							</v-list-item-subtitle>
							<v-list-item-subtitle class="add-on-price" v-if="addOn.price">
								{{ money(addOn.price) }}
							</v-list-item-subtitle>
						</div>

						<div class="add-on-customer-notes" v-if="addOn.customerNotes">
							<v-list-item-subtitle>Notes: {{ addOn.customerNotes }}</v-list-item-subtitle>
						</div>
					</v-list-item>
				</div>

				<v-divider class="order-summary-divider" />

				<v-list-item v-if="$store.getters.shippingFee || $store.getters.discounts || $store.getters.tax">
					<div>
						Sub Total: {{ moneyCurrency($store.getters.subTotal) }}
						<v-list-item-subtitle v-if="$store.getters.tieredDiscount">
							Bulk discount: -{{ moneyCurrency($store.getters.tieredDiscount) }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="$store.state.cart.coupons.length">
							Coupon: -{{ moneyCurrency($store.state.cart.coupons[0].price) }} ({{ $store.state.cart.coupons[0].code }})
						</v-list-item-subtitle>
					</div>
				</v-list-item>

				<v-list-item class="shipping-information-tile" v-if="$store.state.cart.shippingInformation && $store.getters.shippingFee">
					<div>
						<v-list-item-title><b>Shipping:</b> <span v-if="$store.state.cart.shipping">{{ moneyCurrency($store.getters.shippingFee) }}</span></v-list-item-title>
						<v-list-item-subtitle>{{ $store.state.cart.shippingInformation.addressLine1 }}</v-list-item-subtitle>
						<v-list-item-subtitle v-if="$store.state.cart.shippingInformation.addressLine2">
							{{ $store.state.cart.shippingInformation.addressLine2 }}
						</v-list-item-subtitle>
						<v-list-item-subtitle>{{ $store.state.cart.shippingInformation.city }}, {{ $store.state.cart.shippingInformation.state }} {{ $store.state.cart.shippingInformation.zip }}</v-list-item-subtitle>
					</div>
				</v-list-item>

				<v-list-item>
					<div>
						<v-list-item-subtitle v-if="$store.getters.handlingFee">
							{{ $store.state.project.handlingFeeLabel }}: {{ moneyCurrency($store.getters.handlingFee) }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="$store.getters.tax">
							Tax: {{ moneyCurrency($store.getters.tax) }}
						</v-list-item-subtitle>
						<b>Total: {{ moneyCurrency($store.getters.total) }}</b>
					</div>
				</v-list-item>
			</v-list>

			<v-alert
				type="error"
				:model-value="errorMessage"
				transition="scale-transition"
				class="order-alert"
			>
				{{ errorMessage }}
			</v-alert>

			<v-alert
				type="success"
				:model-value="$store.getters.hasAd || $store.getters.hasPersonalizedPages"
				transition="scale-transition"
				class="order-alert"
			>
				You will receive an email invite to design your
				<template v-if="$store.getters.hasAd">
					ad
				</template>
				<template v-else>
					personalized pages
				</template>
				in PLIC Books after your purchase is complete
				<br>
				The invite will go to {{ $store.state.cart.email }}
			</v-alert>

			<v-btn
				text
				@click="prevStep"
			>
				Back
			</v-btn>
			<v-btn
				color="primary"
				@click="nextStep"
				style="margin-left: 1em"
			>
				Place Order
			</v-btn>
		</div>

		<v-progress-circular indeterminate v-if="loading" />
	</div>
</template>

<script>
import getDisplayFields from '../utils/get-display-fields';
import getFieldId from '../utils/get-field-id';
import { money, moneyCurrency } from '@/components/utils/format-money';

export default {
	name: 'OrderSummary',
	props: ['lastStepEvent'],
	emits: ['prev-step'],
	data: function() {
		return {
			loading: false,
			errorMessage: null
		};
	},
	methods: {
		nextStep: function() {
			this.loading = true;
			this.errorMessage = null;

			this.lastStepEvent().then((data) => {
				let orderId = this.$store.state.cart.orderId;
				let accessCode = this.$store.state.cart.accessCode;

				this.$store.dispatch({
					type: 'chargeOrder',
					orderId: orderId,
					chargeToken: data.chargeToken,
					email: this.$store.state.cart.email,
					accessCode: accessCode
				}).then(() => {
					this.loading = false;
					this.$router.push('/receipts/' + accessCode);
				}).catch((error) => {
					if(error && error.response && error.response.data && error.response.data.reason) {
						this.errorMessage = error.response.data.reason.replace('Stripe error: ', '');
					} else if(error && error.message) {
						this.errorMessage = error.message;
					}

					console.error(error);
					this.loading = false;
				});
			}).catch((error) => {
				console.error(error);
				this.loading = false;
			});
		},
		prevStep: function() {
			this.errorMessage = null;
			this.$emit('prev-step');
		},

		fieldId(field) {
			return getFieldId(field);
		},
		displayFields(lineItem) {
			return getDisplayFields(this.$store, lineItem).filter(field => {
				if(['First Name', 'Last Name'].includes(field.field)) {
					return false;
				}

				let id = this.fieldId(field);
				return !!lineItem[id];
			});
		},

		money,
		moneyCurrency
	}
};
</script>

<style scoped>
	.order-summary-divider {
		margin: 0.4em 0;
	}

	.student-information-tile {
		margin: 0.4em 0;
	}

	.shipping-information-tile {
		margin-top: 0;
	}

	.add-on-tile {
		margin-left: 3em;
		flex-direction: column;
	}

	.add-on-customer-notes {
		align-self: start;
		padding-top: 0;
		padding-bottom: 0;
	}

	.add-on-content {
		width: 100%;
	}

	.order-alert {
		margin-bottom: 1em;
	}
</style>