export default function getDisplayFields(store, lineItem) {
	if(lineItem.product.id === 'yearbook') {
		return (store.state.project.yearbookFields && store.state.project.yearbookFields.length) ? store.state.project.yearbookFields : [
			{
				field: 'First Name',
				required: true
			},
			{
				field: 'Last Name',
				required: true
			},
			{
				field: 'Teacher/Home Room',
				required: true
			},
			{
				field: 'Grade',
				required: true
			}
		];
	} else {
		return (store.state.project.adFields && store.state.project.adFields.length) ? store.state.project.adFields : [
			{
				field: 'First Name',
				required: true
			},
			{
				field: 'Last Name',
				required: true
			},
			{
				field: 'Teacher/Home Room',
				required: false
			},
			{
				field: 'Grade',
				required: false
			}
		];
	}
}