export default function itemPrice(item) {
	var price = item.product.price;

	if(item.addOns) {
		item.addOns.forEach((addOn) => {
			price += addOn.price;
		});
	}

	return price;
}