import projectAPI from '../api/project';

export const state = {
	enabled: false,
	id: null,
	name: null,
	logo: null,
	brandingLogo: null,
	brandingName: null,
	products: [],
	subjectFieldValues: null,
	requireTeacherEntry: true,
	taxPercentage: 0,
	handlingFeePercentage: 0,
	handlingFeeFlat: 0,
	handlingFeeLabel: 'Handling Fee',
	shippingSalesTax: true,
	perYearbookShippingFee: false,
	allowPOBoxShipping: true,
	hasCoupons: false,
	tieredDiscounts: [],
	shippingRequired: true,
	yearbookCheckboxLabel: 'Yearbook',
	yearbookFields: null,
	adFields: null,
	checkoutStudentLabel: 'student'
};
export const getters = {
	maxPersonalizationNotesLength() {
		return 100;
	}
};

export const mutations = {
	setProject: function(state, payload) {
		state.enabled = payload.enabled;
		state.id = payload.id;
		state.name = payload.name;
		state.products = payload.products;
		state.logo = payload.logo;
		state.brandingLogo = payload.brandingLogo;
		state.brandingName = payload.brandingName;
		state.subjectFieldValues = payload.subjectFieldValues;
		state.requireTeacherEntry = payload.requireTeacherEntry;
		state.taxPercentage = payload.taxPercentage;
		state.handlingFeePercentage = payload.handlingFeePercentage;
		state.handlingFeeFlat = payload.handlingFeeFlat;
		state.handlingFeeLabel = payload.handlingFeeLabel;
		state.shippingSalesTax = payload.shippingSalesTax;
		state.perYearbookShippingFee = payload.perYearbookShippingFee;
		state.allowPOBoxShipping = payload.allowPOBoxShipping;
		state.hasCoupons = payload.hasCoupons;
		state.tieredDiscounts = payload.tieredDiscounts;
		state.shippingRequired = payload.shippingRequired;
		state.yearbookCheckboxLabel = payload.yearbookCheckboxLabel;
		state.yearbookFields = payload.yearbookFields;
		state.adFields = payload.adFields;
		state.checkoutStudentLabel = payload.checkoutStudentLabel;
	}
};
export const actions = {
	loadProject: function({ state, commit }, payload) {
		return new Promise((resolve, reject) => {
			projectAPI.getProject({
				projectId: payload.id
			}).then(({project, store, orgLogo, brandingLogo, brandingName, subjectFieldValues, projectSettings}) => {
				var products = [];

				if(store.enabled) {
					let addOns = store.addOns;
					if(projectSettings?.waldoIntegrationEnabled === 'plus') {
						addOns.push({
							id: 'waldo-plus-subscription',
							type: 'Waldo Plus',
							description: 'Want to see every photo your student is captured in throughout the year? Try a 7 Day free trial to Waldo Plus.',
							price: 0,
							requireNotes: false
						});
					}

					products.push({
						id: 'yearbook',
						name: store.title,
						description: store.description,
						price: store.price,
						addOns: addOns.map(function(addOn) {
							let clone = { ...addOn };
							clone.enabled = addOn.required ?? false;
							return clone;
						})
					});
				}

				if(store.adoptABookEnabled) {
					products.push({
						id: 'adopt-a-book',
						name: store.adoptABookTitle,
						description: store.adoptABookDescription,
						price: store.price,
						addOns: store.adoptABookAddOnsEnabled ? store.addOns.map(function(addOn) {
							let clone = { ...addOn };
							clone.enabled = addOn.required ?? false;
							return clone;
						}) : []
					});
				}

				if(store.ads && payload.page !== 'book-sales') {
					let types = [];
					if(store.ads.fullPagePrice) {
						types.push({
							id: 'Full Page',
							description: 'Full Page Ad',
							price: store.ads.fullPagePrice
						});
					}
					if(store.ads.halfPagePrice) {
						types.push({
							id: 'Half Page',
							description: 'Half Page Ad',
							price: store.ads.halfPagePrice
						});
					}
					if(store.ads.quarterPagePrice) {
						types.push({
							id: 'Quarter Page',
							description: 'Quarter Page Ad',
							price: store.ads.quarterPagePrice
						});
					}

					[8, 9, 10, 12, 14, 16].forEach((size) => {
						if(store.ads[`${size}thPagePrice`]) {
							types.push({
								id: '1/' + size + 'th Page',
								description: '1/' + size + 'th Page Ad',
								price: store.ads[`${size}thPagePrice`]
							});
						}
					});

					if(types.length) {
						products.push({
							id: 'ads',
							name: 'Ad Space',
							description: store.adsDescription ?? 'Design a page that will go in everyone\'s Yearbook',
							price: types[0].price,
							addOns: [],
							type: types[0].id,
							unique: true,
							types: types
						});
					}
				}

				if(payload.page === 'ad-sales') {
					products = products.filter(product => product.id === 'ads');
				}

				if(state.id && state.id !== project.plicProjectId) {
					commit('clearCart');
				} else {
					commit('updateCartPrices', products);
				}

				commit('setProject', {
					enabled: store.enabled,
					id: project.plicProjectId,
					name: store.title,
					products: products,
					logo: orgLogo,
					brandingLogo: brandingLogo,
					brandingName: brandingName,
					subjectFieldValues,
					requireTeacherEntry: store.requireTeacherEntry,
					taxPercentage: store.taxPercentage || 0,
					handlingFeePercentage: store.handlingFeePercentage || 0,
					handlingFeeFlat: store.handlingFeeFlat || 0,
					handlingFeeLabel: store.handlingFeeLabel || 'Handling Fee',
					shippingSalesTax: store.shippingSalesTax !== false,
					perYearbookShippingFee: store.perYearbookShippingFee || false,
					allowPOBoxShipping: store.allowPOBoxShipping !== false,
					hasCoupons: !!store.coupons,
					tieredDiscounts: store.tieredDiscounts || [],
					shippingRequired: store.shippingRequired,
					yearbookCheckboxLabel: store.yearbookCheckboxLabel ?? 'Yearbook',
					yearbookFields: store.yearbookFields,
					adFields: store.adFields,
					checkoutStudentLabel: store.checkoutStudentLabel ?? 'student'
				});

				if(store.directShippingEnabled) {
					commit('setShippingFee', store.shippingFee);
					if(!store.shippingRequired) {
						commit('setIsDirectShipping', false);
					}
				} else {
					commit('setShippingFee', null);
				}

				resolve(state);
			}).catch(function(error) {
				reject(error);
			});
		});
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};