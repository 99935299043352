<template>
	<v-stepper v-model="currentStep" :items="stepTitles" class="elevation-10 checkout-stepper" :hide-actions="true">
		<v-stepper-window>
			<v-stepper-window-item class="window-item" v-for="(step, index) in steps" :key="step.component.name" :value="index + 1">
				<component
					:is="step.component"
					@next-step="nextStep"
					@prev-step="prevStep"
					:last-step-event="lastStepEvent"
					:is-visible="(index + 1) == currentStep"
				/>
			</v-stepper-window-item>
		</v-stepper-window>
	</v-stepper>
</template>

<script>
import PersonalInformation from './PersonalInformation.vue';
import PaymentInformation from './PaymentInformation.vue';
import OrderSummary from './OrderSummary.vue';
import ShippingInformation from './ShippingInformation.vue';

export default {
	name: 'CheckoutStepper',
	data: function() {
		return {
			currentStep: 1,
			lastStepEvent: null
		};
	},
	computed: {
		steps: function() {
			let steps = [
				{
					title: 'Personal Information',
					component: PersonalInformation
				},
				{
					title: 'Credit Card',
					component: PaymentInformation
				},
				{
					title: 'Confirm',
					component: OrderSummary
				}
			];

			if(this.$store.state.cart.shipping !== null && this.$store.getters.hasYearbook) {
				steps.splice(1, 0, {
					title: 'Shipping',
					component: ShippingInformation
				});
			}

			return steps;
		},
		stepTitles() {
			return this.steps.map(step => step.title);
		}
	},
	methods: {
		nextStep: function(lastStepEvent) {
			this.currentStep++;
			this.lastStepEvent = lastStepEvent;
		},
		prevStep: function() {
			this.currentStep--;
			this.lastStepEvent = null;
		}
	}
};
</script>

<style scoped>
.checkout-stepper:deep(.v-input__details) {
	text-align: start;
}

.checkout-stepper:deep(.v-stepper-window-item) {
	margin-bottom: 0.5em;
}

/* Hack work around for Vuetify adding a blank window at the top of the stepper that adds a ton of visual padding */
.checkout-stepper:deep( > .v-stepper-window) {
	display: none;
}
.checkout-stepper:deep( > .v-stepper-window:last-child) {
	display: block;
}
</style>